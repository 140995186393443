<template>
  <div class="home">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel :height="isMobile ? '45.3vw' : '26.0416vw'" :interval="5000">
        <el-carousel-item v-for="item in imgList" :key="item.id">
          <!-- <img :src="item.src" /> -->
          <!-- <div
            :style="{
              'background-image': `url(${isMobile ? item.xsSrc : item.lgSrc})`,
              'background-position': 'center',
              'background-repeat': 'no-repeat',
              height: '100%',
              'background-size': '100% 100%',
            }"
          ></div> -->
          <figure>
            <img
              :src="isMobile ? item.xsSrc : item.lgSrc"
              style="
                display: inline-block;
                width: 100%;
                max-width: 100%;
                height: auto;
              "
            />
          </figure>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 内容区域 -->
    <!-- <div class="full-bar hidden-md-and-down">
      <ul>
        <li>
          <p class="title">我是标题</p>
          <p class="content">我是内容</p>
        </li>
        <li>
          <p class="title">我是标题</p>
          <p class="content">我是内容</p>
        </li>
        <li>
          <p class="title">我是标题</p>
          <p class="content">我是内容</p>
        </li>
        <li>
          <p class="title">我是标题</p>
          <p class="content">我是内容</p>
        </li>
      </ul>
    </div> -->

    <div class="card-bar">
      <div class="content-box">
        <div class="title-box">
          <div class="u-title">汇盛金丰｜专注于产业 + 商业品牌运营平台</div>
          <div class="u-desc">
            多样化、定制化场景化的服务持续为企业或物业方赋能或创造价值
          </div>
        </div>
        <div class="car-box">
          <el-row :gutter="20">
            <el-col :lg="8" :xs="24" :sm="24" :md="8" class="col-father">
              <div class="car-item">
                <el-card
                  class="box-card"
                  shadow="always"
                  :body-style="{ padding: '0px' }"
                >
                  <div class="my-card-header">
                    <img class="u-img" src="@/assets/images/gouwu.png" />
                    <div class="u-title">拎包办公</div>
                  </div>
                  <div class="m-info">
                    <div class="u-desc">
                      为客户寻找产业园区、写字楼、共享办公、商铺、公寓、住宅及集群卡位地址服务
                    </div>
                    <div class="u-subTitle">
                      让每一个企业主找到自己喜欢的场地
                    </div>
                    <div class="u-userType">找办公室,，妈妈财税、企业服务</div>

                    <router-link
                      to="/service"
                      class="u-detail-btn"
                      target="_blank"
                      >查看详情</router-link
                    >
                  </div>
                </el-card>
              </div>
            </el-col>
            <el-col :lg="8" :xs="24" :sm="24" :md="8" class="col-father">
              <div class="car-item">
                <el-card
                  class="box-card"
                  shadow="always"
                  :body-style="{ padding: '0px' }"
                >
                  <div class="my-card-header">
                    <img class="u-img" src="@/assets/images/licai.png" />
                    <div class="u-title">妈妈财税</div>
                  </div>
                  <div class="m-info">
                    <div class="u-desc">
                      为客户提供公司注册、代理记账及财税云盟服务
                    </div>
                    <div class="u-subTitle">
                      让每一位财税妈妈有自己喜欢的工作
                    </div>
                    <div class="u-userType">公司注册、代理记账、财税云盟</div>

                    <router-link
                      to="/service?anchor=caishui-bar-fat"
                      class="u-detail-btn"
                      target="_blank"
                      >查看详情</router-link
                    >
                  </div>
                </el-card>
              </div>
            </el-col>
            <el-col :lg="8" :xs="24" :sm="24" :md="8" class="col-father">
              <div class="car-item">
                <el-card
                  class="box-card"
                  shadow="always"
                  :body-style="{ padding: '0px' }"
                >
                  <div class="my-card-header">
                    <!-- <img src="@/assets/images/g2.jpg" alt class="img-fluid news-image" /> -->
                    <img class="u-img" src="@/assets/images/diannao.png" />
                    <div class="u-title">企业服务</div>
                  </div>
                  <div class="m-info">
                    <div class="u-desc">
                      为企业提供一站式财税服务、法律服务、知识产权、金融服务等服务
                    </div>
                    <div class="u-subTitle">让每一家入驻企业有着舒心的服务</div>
                    <div class="u-userType">资源招商、咨询顾问、评估</div>

                    <router-link
                      to="/service?anchor=fuwu-bar-fat"
                      class="u-detail-btn"
                      target="_blank"
                      >查看详情</router-link
                    >
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="views-bar-fat">
      <section class="views-bar">
        <div class="u-title">提供领先、可靠、多场景的企业服务方案</div>

        <div class="gezi-bar">
          <el-row :gutter="20">
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi1">
                <div class="u-title">财税服务</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    企业注册怎么办？交给妈妈财税，安全放心又省钱！
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi2">
                <div class="u-title">新媒体推广</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    客户用户画像精准分析，线上线下裂变活动策略，增长模型全方位服务，拎包办公平台为您助力“品
                    效 销”增长
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi3">
                <div class="u-title">金融服务</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    深入园区 服务小微 携手共赢未来，拎包办公平台让您放心选择 效
                    销”增长
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi4">
                <div class="u-title">软件及小程序开发</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    完美办公源于“拎包办公”，为您私享定制化软件开发服务
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi5">
                <div class="u-title">办公室写字楼装修</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    环保▪艺术▪生活
                    在工作中享受，在享受中生活，拎包办公平台让您天天住氧吧
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi6">
                <div class="u-title">办公家具及设备租赁</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    “租”你一臂之力 “赁”辟广阔天地，拎包办公平台为您节省企业成本
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi7">
                <div class="u-title">法律咨询</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    让正义多一点智慧
                    让大家多一份安全，拎包办公平台为您全方位保护
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :xs="12" :sm="12" :md="12" class="col-father">
              <div class="gezi-item gezi8">
                <div class="u-title">知识产权</div>
                <div class="m-hide-part">
                  <div class="u-desc">
                    让我们抵制盗版 尊重智慧的结晶，拎包办公平台为您保驾护航
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </section>
    </div>

    <div class="youshi-bar-fat">
      <section class="youshi-bar">
        <div class="u-title">我们的优势</div>
        <div class="content">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="m-item">
                <img class="u-icon" src="@/assets/images/youshi1.png" />
                <div class="u-title">产业+商业品牌运营服务</div>
                <div class="m-desc">
                  <div class="u-desc">拎包办公平台</div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="m-item">
                <img class="u-icon" src="@/assets/images/youshi2.png" />
                <div class="u-title">妈妈财税职业自营服务</div>
                <div class="m-desc">
                  <div class="u-desc">会计到家平台</div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="m-item">
                <img class="u-icon" src="@/assets/images/youshi3.png" />
                <div class="u-title">城市改造更新运营平台</div>
                <!-- <div class="m-desc">
                  <div class="u-desc">妈妈财税职业自营平台</div>
                </div> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </section>
    </div>

    <!-- 步骤 -->
    <section class="w3l-feature-2 hidden-xs-only" id="process">
      <div class="grid top-bottom py-5">
        <div class="container py-md-5">
          <div class="heading text-center mx-auto">
            <h3 class="head">服务流程</h3>
          </div>
          <div class="middle-section row pt-3 text-center">
            <img
              style="display: block; width: 100%"
              src="@/assets/images/JOINING.jpg"
              alt
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  data () {
    return {
      imgList: [
        {
          id: 0,
          lgSrc: require("../assets/images/carousel.jpg"),
          xsSrc: require("../assets/images/xs1.png"),
        },
        {
          id: 1,
          lgSrc: require("../assets/images/carousel2.jpg"),
          xsSrc: require("../assets/images/xs2.png"),
        },
        {
          id: 2,
          lgSrc: require("../assets/images/carousel3.jpg"),
          xsSrc: require("../assets/images/xs3.png"),
        },
      ],
      isMobile: false,
    };
  },
  created () {
    // 解决方案在渲染之前清空百度分享的一个参数
    window._bd_share_main = "";
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i)) {
      this.isMobile = true;
    }
  },
  mounted () {
    this.shareLinkInit();
    window.addEventListener("resize", this.screenAdapter);
  },
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    shareLinkInit () {
      //分享相关代码
      window._bd_share_config = {
        common: {
          bdSnsKey: {},
          bdText: "",
          bdMini: "1",
          bdMiniList: false,
          bdPic: "",
          bdStyle: "1",
          bdSize: "24",
        },
        share: {},
        selectShare: {
          bdContainerClass: null,
          // 这里和html标签里链接相对应
          bdSelectMiniList: ["qzone", "tsina", "weixin"],
        },
      };
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        "http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js?cdnversion=" +
        ~(-new Date() / 36e5);
      document.body.appendChild(s);
    },
    screenAdapter() {
      const windowWidth =  document.documentElement.clientWidth; //实时屏幕宽度
      // 判断屏幕属于大还是小
      if (windowWidth <= 768) this.isMobile = true;
      windowWidth <= 768 ? this.isMobile = true : this.isMobile = false
    },
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

// .link-wrap {
//   height: 406px;
// }

// .iscenter {
//   text-align: center;
// }

// .btn-news {
//   width: 100%;
//   text-align: center;
// }

.w3l-feature-2 .top-bottom:before {
  background: #f6f6f6;
}

.full-bar ul {
  margin: 0 auto;
  max-width: 1400px;
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding: 24px 0;
  background-color: #fff;

  li {
    text-align: center;
    width: 25%;
    padding: 0 20px;
    color: #666;
  }

  // li:hover {
  //   .title {
  //     color: #409eff;
  //   }
  // }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  .content {
    padding-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }
}

.card-bar {
  background-color: #f3f5fc;
  padding: 88px 0;

  .content-box {
    margin: 0 auto;
    max-width: 1400px;

    .title-box {
      text-align: center;

      .u-title {
        font-size: 32px;
        line-height: 45px;
        color: #333;
      }

      .u-desc {
        margin-top: 16px;
        font-size: 17px;
        line-height: 16px;
        color: #666;
      }
    }

    .car-box {
      margin-top: 48px;

      .car-item {
        .my-card-header {
          position: relative;
          height: 108px;
          background: linear-gradient(120deg, #f6d365 0%, #fda085 100%);

          // background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%);
          .u-img {
            position: absolute;
            right: 16px;
            top: 50%;
            display: inline-block;
            width: 70px;
            height: 70px;
            transform: translateY(-50%);
          }

          .u-title {
            position: absolute;
            top: 40px;
            left: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #fff;
          }
        }

        .m-info {
          padding: 32px 24px 20px;

          .u-desc {
            font-size: 13px;
            line-height: 20px;
            height: 40px;
            color: #666;
          }

          .u-subTitle {
            margin-top: 18px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #333;
          }

          .u-subTitle::before {
            content: '';
            position: relative;
            display: inline-block;
            margin-right: 8px;
            top: 1px;
            width: 3px;
            height: 12px;
            background: #f0ad4e;
          }

          .u-userType {
            height: 30px;
            margin-top: 8px;
            font-size: 12px;
            line-height: 17px;
            color: #666;
          }

          .u-detail-btn {
            display: inline-block;
            margin-top: 34px;
            width: 100%;
            height: 40px;
            border: 1px solid #409eff;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 38px;
            color: #409eff;
            text-align: center;
          }

          .u-detail-btn:hover {
            background: #409eff;
            color: #fff;
          }
        }
      }
    }
  }
}

.views-bar-fat {
  background: #fbfbff;
}

.views-bar {
  max-width: 1400px;
  margin: 0 auto;
  padding: 88px 0 43px;
  // background: #fbfbff;
  font-family: PingFang SC, PingFangSC-Light, Helvetica, Tahoma, Arial,
    Microsoft YaHei, Hiragino Sans GB, WenQuanYi Micro Hei, sans-serif;

  .u-title {
    text-align: center;
    font-size: 32px;
    line-height: 45px;
    color: #333;
  }

  .gezi-bar {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    .col-father {
      text-align: center;
    }
    .gezi-item {
      position: relative;
      display: inline-block;
      vertical-align: top;
      // margin: 18px 17px 0 0;
      margin-top: 18px;
      width: 337px;
      height: 212px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      // text-align: center;
      color: #fff;
      cursor: pointer;

      .u-title {
        padding-top: 105px;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
      }

      .m-hide-part {
        display: none;
        .u-desc {
          // height: 72px;
          padding-top: 16px;
          font-size: 14px;
          line-height: 24px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #fff;
        }
      }
    }

    .gezi1 {
      background: url(../assets/images/gezi1.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi2 {
      background: url(../assets/images/gezi2.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi3 {
      background: url(../assets/images/gezi3.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi4 {
      background: url(../assets/images/gezi4.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi5 {
      background: url(../assets/images/gezi5.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi6 {
      background: url(../assets/images/gezi6.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi7 {
      background: url(../assets/images/gezi7.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi8 {
      background: url(../assets/images/gezi8.png) center no-repeat;
      background-size: 100% 100%;
    }

    .gezi-item:hover {
      // background: linear-gradient(
      //   90deg,
      //   #0056ff,
      //   rgba(0, 86, 255, 0.56)
      // ) !important;
      background: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
      border: 1px solid #ededf5;
      padding: 37px 33px 0;
      text-align: left;

      .u-title {
        padding-top: 0;
        text-align: left;
      }

      .m-hide-part {
        display: block;
      }
    }

    .no-marg {
      margin-right: 0;
    }
  }
}

.youshi-bar-fat {
  background: #fbfbff;
}

.youshi-bar {
  max-width: 1400px;
  margin: 0 auto;
  padding: 44px 0 88px;
  text-align: center;
  font-family: PingFang SC, PingFangSC-Light, Helvetica, Tahoma, Arial,
    Microsoft YaHei, Hiragino Sans GB, WenQuanYi Micro Hei, sans-serif;

  .u-title {
    font-size: 32px;
    line-height: 45px;
    color: #333;
  }

  .content {
    margin-top: 46px;
    .m-item {
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      .u-icon {
        display: inline-block;
        width: 220px;
        height: 220px;
      }

      .u-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #333;
        margin-top: 20px;
      }

      .m-desc {
        margin: 12px auto 0;

        .u-desc {
          font-size: 14px;
          line-height: 24px;
          color: #666;
        }
      }
    }
  }
}

.heading h3 {
  font-size: 32px;
  line-height: 45px;
  color: #333;
  font-weight: normal;
  margin-bottom: 46px;
}

@media screen and (max-width: 768px) {
  .card-bar .content-box {
    margin: 0 30px;

    .title-box {
      text-align: left;
      .u-title {
        font-size: 24px;
      }
      .u-desc {
        line-height: 32px;
      }
    }

    .car-box {
      margin-top: 24px;
    }

    .col-father {
      margin-bottom: 15px;
    }
  }

  .views-bar {
    padding: 42px 30px 43px;

    .u-title {
      text-align: left;
      font-size: 24px;
    }

    .gezi-bar {
      .gezi-item {
        width: 154px;
        height: 96px;

        .u-title {
          padding-top: 40px;
          font-size: 12px;
          font-weight: normal;
          text-align: center;
        }
      }
    }
  }

  .youshi-bar {
    padding-left: 30px;
    padding-right: 30px;
    .u-title {
      font-size: 24px;
      text-align: left;
    }
    .m-desc {
      text-align: left;
    }
  }
}

@media screen and (max-width: 767px) {
  // .block {
  //   padding-top: 50px;
  // }

  .card-bar {
    padding: 25px 0;
  }

  .youshi-bar {
    .content {
      .m-item {
        .u-icon {
          display: inline-block;
          width: 80px;
          height: 80px;
        }

        .u-title {
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .views-bar {
    .gezi-bar {
      .gezi-item {
        width: 315px;
        height: 196px;

        .u-title {
          padding-top: 82px;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
